.flex {
	display: flex;
}

/* General styles */
body {
	position: relative;
	margin: 0 auto;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: var(--color-white);
}

#page-container {
	overflow: hidden;
}

#content-wrap {
	padding-top: var(--headerHeight);
}

[data-absolute] {
	position: absolute;
}

/* Headings */
.text-200 {
	font-size: var(--p-size);
	font-weight: 300;
	line-height: var(--p-lh);
	color: var(--text-200);
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: bold;
	line-height: var(--h1-lh);
	margin-bottom: var(--h1-mb);
}

h1 {
	font-size: var(--h1-size);
}

h2 {
	font-size: var(--h2-size);
	margin-bottom: var(--h2-mb);
}

h3 {
	font-size: var(--h3-size);
	line-height: var(--h3-lh);
	margin-bottom: var(--h3-mb);
	font-weight: 700;
}

h4 {
	font-size: var(--h4-size);
	font-weight: 300;
	line-height: var(--h4-lh);
	margin-bottom: var(--h4-mb);
}

h5 {
	font-size: var(--h5-size);
	font-weight: 600;
	line-height: var(--h5-lh);
	margin-bottom: var(--h5-mb);
}

/* Paragraphs */
p {
	font-size: var(--p-size);
	font-weight: 400;
	line-height: var(--p-lh);
	margin-bottom: var(--p-mb);
	color: var(--text-100);

	&.no-max {
		max-width: 800px;
	}
}

/* Images */
img {
	pointer-events: none;
	user-select: none;
}

/* Buttons */
button.button,
a.button {
	padding: 16px 40px;
	border-radius: 48px;
	background-color: var(--color-white);
	color: var(--color-black);
	flex-grow: 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	text-align: center;
	position: relative;
	cursor: pointer;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 48px;
		opacity: 0;
		z-index: -1;
		filter: blur(20px);
		background: var(--button-gradient);
		transition: opacity 0.5s ease;
	}

	&.pink {
		&::before {
			background: rgba(204, 51, 204, 1);
		}
	}

	&:hover {
		&::before {
			opacity: 1;
		}
	}

	&.small {
		width: 200px;
	}

	&.large {
		width: var(--btn-large-w);
	}

	&.full {
		width: var(--btn-full-w);
	}

	&.headerBtn {
		padding: var(--btn-padding);
		font-size: var(--btn-size);
		line-height: var(--btn-lh);
		border-radius: var(--btn-radius);
	}

	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;

		&:before {
			opacity: 0 !important;
		}
	}

	&:active {
		.gradient {
			opacity: 1;
		}
	}

	&.icon {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 13px;

		img {
			width: 19px;
			height: 19px;
		}

		span {
			display: none;
		}
	}
}

@media screen and (min-width: 706px) {

	button.button,
	a.button {
		&.icon {
			padding: 16px;

			img {
				width: 24px;
				height: 24px;
			}

		}
	}
}

@media screen and (min-width: 1024px) {

	button.button,
	a.button {

		&.icon {
			padding: var(--btn-padding);

			span {
				display: block;
			}

			img {
				margin-right: 8px;
				width: 20px;
				height: 20px;
			}
		}
	}
}

@media(hover: hover) and (pointer: fine) {

	button.button,
	a.button {
		&:hover {
			.gradient {
				opacity: 1;
			}
		}
	}
}

/* Glass */
.glass {
	-webkit-backdrop-filter: var(--glass-filter) !important;
	backdrop-filter: var(--glass-filter) !important;
	border: var(--glass-border) !important;
	background-color: var(--glass-background) !important;
	color: var(--color-white) !important;
}

/* Media Queries */
@media screen and (max-width: 1023px) {
	p.no-max-tbl {
		max-width: none;
	}
}

.reveal,
[data-reveal] {
	opacity: 0;
	transform: translateY(40px);
	transition: opacity 0.55s ease, transform 0.55s ease;

	&.order-1 {
		transition-delay: 0.1s;
	}

	&.order-2 {
		transition-delay: 0.2s;
	}

	&.order-3 {
		transition-delay: 0.3s;
	}

	&.order-4 {
		transition-delay: 0.4s;
	}

	&.order-5 {
		transition-delay: 0.5s;
	}

	&.order-6 {
		transition-delay: 0.6s;
	}

	&.order-7 {
		transition-delay: 0.7s;
	}

	&.order-8 {
		transition-delay: 0.8s;
	}

	&.order-9 {
		transition-delay: 0.9s;
	}

	&.order-10 {
		transition-delay: 1s;
	}
}

.animated {
	opacity: 1;
	transform: none;
	transition: opacity 0.55s ease, transform 0.55s ease;
}

.accent {
	color: var(--primary-200) !important;
}

.bold {
	font-weight: bold !important;
}

span,
a {
	&.accent {
		color: var(--accent-100) !important;
	}

	&.primary-100 {
		color: var(--primary-100) !important;
	}

	&.primary-200 {
		color: var(--primary-200) !important;
	}

	&.primary-300 {
		color: var(--primary-300) !important;
	}
}

.info-sections {
	flex-direction: column;

	section {
		padding: 0 var(--mg-lr);
		//max-width: calc(1040px + var(--mg-lr) + var(--mg-lr));
		width: 100%;
		margin: 0;
		height: 100%;
		scroll-margin-top: var(--headerHeight);
		position: relative;
		padding-bottom: calc(var(--sectionBottom) / 2);
		display: flex;
		justify-content: center;
		max-width: 1040px;
		flex-direction: column;

		p {
			font-size: 16px;
			line-height: 24px;
			color: var(--text-200);
			font-weight: 300;
		}

		ul {
			margin: 24px;

			li {
				list-style: inside !important;
				list-style-type: circle;
				margin-bottom: 8px;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}
}

@media screen and (min-width:1180px) {

	.main-sections,
	.info-sections {
		display: flex;
		align-items: center;
		margin-left: none;

		.section {
			width: 1040px;
		}
	}
}