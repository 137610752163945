/* -------------------------------------------------------------------------- */
/*                                welcome_SECTION                                */
/* -------------------------------------------------------------------------- */

html {
    background-color: #101012;
}

.space {
    height: var(--spacerHeight);
}

#content-wrap {
    padding-top: 0 !important;
}




.main-sections {
    display: flex;
    flex-direction: column;
    padding: 0 var(--mg-lr);
    padding-top: var(--headerHeight);
    position: relative;
    top: 0;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    .space {
        height: var(--spacerHeight);

    }

    .section {
        margin: 0;
        margin-bottom: calc(var(--sectionBottom) * 1.5);
        height: 100%;
        /* width: 100%; */
        scroll-margin-top: var(--headerHeight);
        position: relative;

    }
}




@media screen and (max-width:375px) {
    .main-sections {
        max-width: 375px;
    }
}


@media screen and (min-width:1180px) {
    .main-sections {
        display: flex;
        align-items: center;
        margin-left: none;

        .section {
            width: 1040px;
        }
    }
}



.welcome-section {
    display: flex;
    flex-direction: column;
    gap: 40px;

    .card-toon-wrapped {
        position: relative;

        .newlogo {
            position: absolute;
            transform: rotate(4deg);
            z-index: 1;
            right: 0;
            top: -30px;
        }

        .card {
            border-radius: 40px;
            //background: linear-gradient(180deg, rgba(204, 51, 204, 0.30) 0%, rgba(63, 63, 70, 0.30) 100%, );
            background: linear-gradient(180deg, rgba(63, 63, 70, 0.30) 0%, rgba(0, 102, 255, 0.30) 100%);
            backdrop-filter: blur(8px);
            padding: 24px;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
            min-height: 584px;
            overflow: hidden;

            /* p {
                    font-weight: 600;
                } */

            .info {
                display: flex;
                flex-direction: column;
            }

            .link {
                margin-top: 24px;
                align-self: center;
                z-index: 1;
            }

            .mbl {
                position: absolute;
                bottom: 16px;
                align-self: center;
                width: 100%;
                min-width: 315px;
                //max-width: 400px;
                //border-bottom-left-radius: 40px;
                //border-bottom-right-radius: 40px;
                z-index: 0;
            }

            .tbl,
            .dsk {
                display: none;
            }
        }

        .starBig {
            animation: rotate 50s infinite linear;
            left: -60px;
            top: -60px;
            height: auto;
        }



        .starRed {
            //top: 96px;
            right: -225px;
            bottom: -10%;
            transform: rotate(-12deg);
            z-index: 1;
            height: auto;
        }


        .starSmall {
            left: -60px;
            top: 40%;
            transform: rotate(34deg);
            z-index: 1;
            height: auto;
        }

        .starSmallOther {
            left: -50px;
            top: -80px;
            transform: rotate(44deg);
            height: auto;
        }


    }


    .link {
        display: flex;
        align-self: center;
    }

    .paragraph {
        margin-bottom: 48px;
    }

    .link {
        margin-top: 8px;
    }

    .cards-main {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }

    .card-wrapped {
        position: relative;

        a.button {
            &:before {
                background: rgba(204, 51, 204, 1);
            }
        }

        .diamond {
            left: -40px;
            top: -40px;
            display: none;
        }

        .card {
            border-radius: 40px;
            background: linear-gradient(180deg, rgba(204, 51, 204, 0.30) 0%, rgba(63, 63, 70, 0.30) 100%, );
            backdrop-filter: blur(8px);
            padding: 24px;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
            min-height: 600px;
            overflow: hidden;

            /* p {
                font-weight: 600;
            } */

            .info {
                display: flex;
                flex-direction: column;
            }

            .link {
                margin-top: 24px;
                align-self: center;
                z-index: 1;
            }

            .dsk {
                position: absolute;
                bottom: -25px;
                align-self: center;
                width: 100%;
                min-width: 315px;
                max-width: 400px;
                z-index: 0;
            }

            .blur {
                background: #F857ED;
                filter: blur(61px);
                width: 50%;
                height: 20%;
                border-radius: 50%;
                position: absolute;
                bottom: 20%;
                left: calc(50vw - 30%);
            }

            .tbl {
                display: none;
            }
        }

        .starBig {
            animation: rotate 50s infinite linear;
            left: -60px;
            top: -60px;
            height: auto;
        }



        .starRed {
            bottom: 10%;
            right: -30px;
            transform: rotate(109.152deg);
            z-index: 1;
            height: auto;
        }


        .starSmall {
            left: -30px;
            top: 106px;
            transform: rotate(-79.109deg);
            height: auto;
        }


    }

    .card-block {
        position: relative;

        .new {
            top: -22px;
            left: -11px;
        }

        .coin3 {
            display: none;
        }

        .coinbehind {
            top: 47%;
            left: -9%;
        }



        .telegram {
            z-index: 1;
            bottom: 3%;
            right: -14%;
            animation-name: moving2;
        }

        .heart {
            top: -5%;
            right: 5%;
        }



        .card {
            border-radius: 40px;
            background: linear-gradient(180deg, rgba(63, 63, 70, 0.30) 0%, rgba(39, 161, 255, 0.30) 100%);
            backdrop-filter: blur(8px);
            padding: 24px;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
            height: 584px;
            overflow: hidden;

            a.button {
                &:before {
                    background: #27A1FF;
                }
            }

            .info {
                display: flex;
                flex-direction: column;
            }



            .phone {
                margin-top: 8px;
                width: 141%;
                max-width: 500px;
                align-self: center;
                position: relative;
            }
        }

    }

    .card-main-block {
        position: relative;

        .coin3 {
            display: none;
        }

        .coin2 {
            right: 3%;
            bottom: -20%;
        }

        .coin1 {
            left: 60%;
            top: -8%;
            z-index: 1;
        }

        .card {
            border-radius: 40px;
            background: linear-gradient(180deg, rgba(46, 46, 49, 0.30) 0%, rgba(92, 96, 100, 0.30) 100%);
            backdrop-filter: blur(8px);
            padding: 24px;
            padding-bottom: 32px;
            display: flex;
            flex-direction: column;
        }
    }
}


@media screen and (min-width:670px) {
    .welcome-section {

        .card-toon-wrapped {
            position: relative;

            .newlogo {
                height: auto;
                width: 189px;
                right: 70px;
            }

            .card {
                padding: 40px;
                min-height: 376px;

                .info {
                    max-width: 344px;
                    z-index: 1;
                }

                .link {
                    align-self: flex-start;

                }

                .tbl {
                    display: block;
                    position: absolute;
                    right: -10%;
                    top: 0;
                    height: 100%;
                    //width: auto;
                }

                .mbl,
                .dsk {
                    display: none;
                }
            }





            .starRed {
                bottom: -12%;
                right: -220px;
                width: 360px;
            }


            .starSmall {
                left: -75px;
                top: initial;
                bottom: 39px;
                width: 120px;
            }

            .starSmallOther {
                width: 200px;
            }


        }

        .link {
            align-self: flex-start;
        }

        .link {
            margin-top: 24px;
        }

        .new {
            width: 78.664px;
            height: 51.956px;
            top: -23px;
        }

        .card-wrapped {
            position: relative;

            .card {
                padding: 40px;
                min-height: 376px;

                .info {
                    max-width: 300px;
                    z-index: 1;
                }

                .link {
                    align-self: flex-start;

                }

                .tbl {
                    display: block;
                    position: absolute;
                    right: 0%;
                    top: 20px;
                    height: 100%;
                    width: auto;
                }

                .blur {
                    right: 20%;
                    left: initial;
                    width: 150px;
                    height: 150px;
                    bottom: 30%;
                }

                .mbl,
                .dsk {
                    display: none;
                }
            }

            .starBig {
                animation: rotate 50s infinite linear;
                left: -50px;
                top: -50px;
                width: 128px;
            }



            .starRed {
                bottom: 15%;
                right: -40px;
                width: 48px;
            }


            .starSmall {
                left: -45px;
                top: 106px;
                width: 40px;
            }


        }

        .card-block {
            .telegram {
                bottom: 14%;
                right: 35%;
                width: 146px;
                height: auto;
            }

            .heart {
                top: -12%;
                right: 15%;
                width: 88px;
                height: 88px;
            }

            .coinbehind {
                top: 37%;
                left: -5%;
            }





            .card {
                padding: 40px;
                display: flex;
                flex-direction: row;
                height: auto;


                .info {
                    max-width: calc(100% - 263px);
                }

                .phone {
                    width: 413px;
                    max-width: none;
                    align-self: center;
                    position: absolute;
                    right: -105px;
                    top: 0px;
                    margin-top: 0px;
                }
            }
        }

        .card-main-block {
            .coin2 {
                right: 20%;
                bottom: -20%;
            }

            .coin1 {
                left: 30%;
                top: -8%;
            }

            .card {
                padding: 40px;
            }
        }
    }

}


@media screen and (min-width:745px) {
    .welcome-section {
        .card-toon-wrapped {
            .card {
                .tbl {
                    right: 0;
                    //height: auto;
                    //max-width: 50%;
                }
            }
        }

        .card-wrapped {
            .card {

                .info {
                    max-width: 344px;

                }

                .tbl {
                    right: 0;
                    height: auto;
                    max-width: 45%;
                    top: -20px;
                }
            }
        }
    }
}


@media screen and (min-width:1024px) {

    .welcome-section {
        gap: 16px;

        .card-toon-wrapped {

            .newlogo {
                width: 232px;
                right: 20%;
                top: -52px;
            }

            .starSmall {
                bottom: 44%;
                transform: rotate(34deg);
            }

            .starRed {
                bottom: -47%;
                right: -180px;
            }

            .starSmallOther {
                left: 49px;
                top: -118px;
            }

            .card {
                .tbl {
                    display: none;
                }

                .info {
                    max-width: 550px;
                }

                .dsk {
                    display: block;
                    height: calc(100% - 65px);
                    position: absolute;
                    right: 32px;
                    top: initial;

                }
            }
        }

        .cards-main {
            flex-direction: row;
            gap: 16px;
        }

        .new {
            width: 94.397px;
            height: 62.347px;
            top: -34px;
            left: -18px;
        }

        .card-wrapped {
            .diamond {
                left: -60px;
                top: -60px;
                width: 120px;
                height: auto;
            }

            .card {
                .tbl {
                    display: none;
                }

                .info {
                    max-width: 550px;
                }

                .dsk {
                    display: block;
                    height: 100%;
                    position: absolute;
                    right: -10px;
                    top: 0;

                }
            }
        }

        .card-block {
            .coinbehind {
                display: block;
                top: 53%;
                left: -16%;
            }

            .coin3 {
                display: block;
                left: 40%;
                bottom: -25%;
            }




            .telegram {
                width: 215.3px;
                height: 215.3px;
            }

            .heart {
                width: 98.1px;
                height: 98.1px;
                right: 31%;
            }



            .card {
                padding-right: 0;
                min-height: 512px;
                height: 100%;
                max-width: 688px;
                // max-width: 688px;



                .info {
                    max-width: calc(100% - 340px);
                    height: 100%;
                    justify-content: space-between;

                    p {
                        max-width: calc(100% - 28px);
                    }
                }

                .phone {
                    right: -45px;
                    margin-top: 16px;
                }
            }
        }

        .card-main-block {

            .coin3 {
                display: none;

            }

            .coin1 {
                display: none;
            }

            .coin2 {
                right: -20%;
                bottom: 11%;
            }

            .card {
                height: 100%;
                max-width: 336px;
                min-height: 512px;

                .info {
                    height: 100%;
                    justify-content: space-between;
                }
            }
        }
    }

}

@media screen and (min-width: 1200px) {
    .welcome-section {
        .card-wrapped {
            .card {

                .info {
                    max-width: 600px;
                }

                .dsk {
                    right: 0;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .welcome-section {
        .card-toon-wrapped {
            .card {

                .info {
                    max-width: 600px;
                }

                .dsk {
                    right: 0;
                }
            }
        }
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: var(--footerBottom);

    a.button {
        &:before {
            background: #27A1FF;
        }
    }

    .link {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.16px;
        text-transform: capitalize;
        padding: 8px 0px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        gap: 16px;
        color: inherit;

    }

    .blur {
        width: 100%;
        height: 1000px;
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        text-align: center;
        mix-blend-mode: screen;
        background: radial-gradient(circle, rgba(0, 102, 255, 0.6) 0%, rgba(0, 212, 255, 0) 100%);
        filter: blur(100px);
        opacity: .8;
        background-repeat: no-repeat;
        z-index: -1;
        -webkit-transform: translate3d(0, 0, 0);
        top: -200px;
    }

    .content {
        position: relative;
        max-width: calc(1040px + 2 * var(--mg-lr));
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: stretch;
        flex-direction: row;
        padding: 0 var(--mg-lr);
        gap: 24px;

        p {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
            opacity: .6;
        }

        .explore {

            .linkBlock {
                display: flex;
                flex-direction: column;
                gap: 16px;
                width: max-content;
            }
        }

        .participate {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            text-align: right;
            gap: 24px;

            .buttonBlock {
                display: flex;
                flex-direction: row;
                gap: 16px;
                align-items: flex-end;
                flex-wrap: wrap;
                justify-content: flex-end;
            }
        }
    }


}


@media screen and (min-width:706px) {
    .footer {
        .content {
            .participate {
                p {
                    margin-bottom: 32px;
                }

                align-items: flex-start;
                text-align: left;

                .buttonBlock {
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                }
            }
        }
    }
}


@media screen and (min-width:1024px) {
    .footer {
        .content {
            .explore {
                .linkBlock {
                    gap: 8px;
                }
            }

            .participate {
                .buttonBlock {
                    gap: 48px;
                }
            }
        }
    }

}