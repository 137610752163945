/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */

.header {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    background: none;
    border: solid 1px transparent;
    background-color: transparent;
    transition: all 1s;
    position: fixed;
    width: 100%;
    z-index: 1000 !important;
    top: 0 !important;
    margin-top: 0 !important;
    left: 0;

    &.scrolled {
        border-bottom: solid 1px rgba(255, 255, 255, 0.08);
        background-color: rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(12px);
    }
}

.header-container {
    display: flex;
    /* max-width: calc(1440px - var(--mg-lr) - var(--mg-lr)); */
    max-width: 1440px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 16px var(--mg-lr);
    position: relative;
}



.header__logo {
    -webkit-text-stroke: 1px #fff;
    font-family: Mulish, sans-serif;
    font-size: var(--logo-size);
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    cursor: pointer;
    z-index: 101;

    .logo-link {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    img.back-arrow {
        width: 24px;
        height: 24px;
    }

}

@media screen and (max-width:705px) {
    .header__logo {
        font-size: 16px;

        .back-link {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        img {
            width: 16px;
            height: 16px;
        }
    }

}